<template>
  <div id="award-edit">
    <v-card>
      <v-card-title>
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <router-link to="/zone/officers-award/list">
          <v-btn
            color="blue"
            min-width="92"
            rounded
            medium
            class="font-size-h6 px-3 py-3 mr-3 white--text"
          >
            Back
          </v-btn>
        </router-link>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="btn font-size-h6 px-3 py-3 mr-3 white--text"
          >Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="row != ''">
          <h3 class="text-dark text-center mb-3">Award Details</h3>
          <v-row>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Status</h6>
              <h6 class="text-dark">
                <v-chip :color="row.AwardStatusColor" draggable class="font-size-h5 white--text px-4 py-3">
                  {{ row.AwardStatusTxt }}
                </v-chip>
                <!-- {{ row.AwardStatusTxt }} -->
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Register Number</h6>
              <h6 class="text-dark">
                {{ row.AwardRegisterNumber }}
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Title</h6>
              <h6 class="text-dark">
                {{ row.AwardTypeTitleTxt }}
              </h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="getTotalZoneConOfficersAwardsDetails"
          >
            <h3 class="text-dark text-center">Judging Criteria</h3>
            <v-row wrap class="mb-5 p-5">
              <v-col v-if="row == ''">
                <h5 class="text-center text-dark">Nil.</h5>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-for="(item, index) in row.AwardAnswersArray"
                :key="index"
              >
                <template v-if="item.CriteriaTitleFlag">
                  <h3 class="criteria-title">
                    {{ item.CriteriaTitle }}
                    <small> (score - {{ item.CriteriaScore }}) </small>
                  </h3>
                  <p></p>
                </template>
                <label :for="item.AnswerId" class="criteria-question-text">
                  <h6 class="text-dark">
                    <span class="text-danger">* </span>
                    {{ item.QuestionsText }}
                  </h6>
                </label>
                <v-textarea
                  :id="item.AnswerId"
                  v-model="item.AnswerText"
                  value=""
                  :maxlength="item.QuestionsCharsCount"
                  :counter="item.QuestionsCharsCount"
                  :rules="AnswerRules"
                  outlined
                  solo
                >
                </v-textarea>
              </v-col>
            </v-row>

            <template v-if="row != ''">
              <h6 class="text-dark mt-0">
                Add / Delete Attachment (max -
                {{ AllProjectMax }})
                <v-btn
                  icon
                  color="blue"
                  v-if="AllProjectMax >= AllProjectCount"
                  @click="addNewRow()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </h6>
              <br />
              <v-row>
                <v-col
                  v-for="(data, index) in row.AnswerAttachmentOfficers"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="index"
                >
                  <v-card>
                    <v-card-text>
                      <h6 class="text-right">
                        <v-btn icon color="red" @click="deleteRow1(index)">
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </h6>
                      <h6 align="center">
                        <!-- <img
                          style="padding: 5px"
                          width="70"
                          height="70"
                          :src="data.EventImagesArray"
                        /> -->
                        <viewer rebuild :images="data.EventImagesArray">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data.EventImagesArray"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{ data.EventDate }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{ data.EventName }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  v-for="(data1, k) in AllProject"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="k"
                >
                  <v-card>
                    <v-card-text>
                      <h6 class="text-right">
                        <v-btn icon color="red" @click="deleteRow(k)">
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </h6>
                      <h6 align="center">
                        <viewer rebuild :images="data1.AnswerEventImageUrl">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data1.AnswerEventImageUrl"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{
                          data1.AnswerEventDate
                        }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{
                          data1.AnswerEventName
                        }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <v-row>
              <v-col align="center" cols="12" md="12">
                <!-- <v-btn
                  @click="resetForm"
                  elevation="30"
                  rounded
                  medium
                  color="brown"
                  class="font-size-h6 px-6 mr-3 white--text"
                >
                  Reset
                </v-btn> -->
                <v-btn
                  v-if="row != ''"
                  @click.prevent="getTotalZoneConOfficersAwardsDetails(4)"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#1db954"
                  class="font-size-h6 px-6 mr-3 white--text"
                >
                  Save
                </v-btn>
              </v-col>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="row != ''"
                  :disabled="!valid1"
                  @click.prevent="getTotalZoneConOfficersAwardsDetails(3)"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="blue"
                  class="font-size-h6 px-6 white--text"
                >
                  Final Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="70%">
      <attachment-edit
        pageTitle="Add Project Details"
        :recordData="selectedRecord"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt($event)"
        v-if="addRecordPrompt"
      ></attachment-edit>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

import AttachmentEdit from "@/view/pages/erp/jci-award/zonecon/officers/AttachmentEdit.vue";

export default {
  mixins: [common],
  components: { AttachmentEdit },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,
      CardLoadingFlag: false,
      UploadFlag: false,
      addButtonFlag: true,

      Status: "",

      ZoneconOfficersAwardStartDate: "",
      ZoneconOfficersAwardEndDate: "",
      TotalZoneconOfficersAward: 0,
      TotalApplied: 0,
      TotalPartiallySaved: 0,

      StartDateFlag: false,
      EndDateFlag: false,

      CurrentDateTime: "",
      CurrentDate: "",
      CurrentTime: "",
      CurrentAmPm: "",

      rows: {},
      AwardId: "",
      AwardTypeId: "",

      row: [],
      // CriteriaAnswers: [],
      Project: [],
      AllImages: [],
      AllProject: [],
      AllProjectMax: 10,
      AllProjectCount: 0,

      selectedRecord: "",
      Id: "",
      showTextBox: false,

      addRecordPrompt: false,

      ImageUploadFlag: false,

      AnswerRules: [(v) => !!v || "Answer is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    rows: function () {
      console.log("watch rows called");
      this.getCurrentDateTime();
    },
    CurrentDateTime: function () {
      console.log("watch CurrentDateTime called");
      this.getDeadlineDetails();
    },
    row: function () {
      console.log("watch row called");
      this.AwardTypeId = this.row.AwardTypeId;
      this.calculateAllProjectCount();
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "zone_officers_award",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var id = this.$route.query.id;
      console.log({ id });
      this.AwardId = id;

      this.getTableRecords();
    },
    resetForm() {
      console.log("resetForm called");
      var rows = this.CriteriaAnswers;

      rows.map((row, index) => {
        row.AnswerText = "";
      });
      this.AllProject = {};
      this.AllImages = {};

      this.getTableRecords();
    },
    addNewRow() {
      console.log("addNewRow called");
      this.addRecordPrompt = true;
    },
    deleteRow(id) {
      console.log("deleteRow called");
      console.log({ id });

      this.AllProject.splice(id, 1);
      this.AllImages.splice(id, 1);

      this.calculateAllProjectCount();
    },
    deleteRow1(id) {
      console.log("deleteRow1 called");
      console.log({ id });

      this.row.AnswerAttachmentOfficers.splice(id, 1);

      this.calculateAllProjectCount();
    },
    calculateAllProjectCount() {
      console.log("calculateAllProjectCount called");
      this.AllProjectCount =
        this.row.AnswerAttachmentOfficers.length + this.AllProject.length;
      console.log("AllProjectCount =", this.AllProjectCount);
    },
    hideAddRecordPrompt(tr) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ tr });

      if (tr.EventName != "") {
        var count = this.AllProject.length;
        var id = count == 0 ? 0 : count + 1;

        this.AllProject.push({
          AnswerEventId: id,
          AnswerEventName: tr.EventName,
          AnswerEventDate: tr.EventDate,
          AnswerEventDescription: tr.EventDescription,
          AnswerEventImageName: tr.EventImageName,
          AnswerEventImageUrl: tr.EventImageUrl,
          AnswerEventImage: tr.EventImage,
        });

        this.AllImages.push({
          AnswerEventId: id,
          AnswerEventName: tr.EventName,
          UploadedImage: tr.EventImage.UploadedImage,
        });
      }
      console.log("AllProject = " + JSON.stringify(this.AllProject));
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var AwardId = this.AwardId;
      console.log({ AwardId });

      if (AwardId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/show";
        var upload = {
          UserInterface: 1,
          Award: AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.RecordFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.RecordFlag = flag;

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));

              thisIns.row = records;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Award details not found.";
        console.log("error=" + message);
      }
    },
    getTotalZoneConOfficersAwardsDetails(status) {
      console.log("getTotalZoneConOfficersAwardsDetails called");

      var validate1 = true;
      console.log({ validate1 });

      console.log({ status });
      this.Status = status;

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/total-awards";

        var AwardCategory = 3;
        var AwardGroup = 3;

        if (this.RoleId == 2) {
          // For Admin
          var upload = {
            UserInterface: 2,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Zone: this.ZoneCode,
            Lom: this.LomCode,
          };
        } else {
          // For Others
          var upload = {
            UserInterface: 2,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.rows = records;
              thisIns.ZoneconOfficersAwardStartDate = records.StartDateTxt;
              thisIns.ZoneconOfficersAwardEndDate = records.EndDateTxt;
              thisIns.TotalZoneconOfficersAward = records.TotalAward;
              thisIns.TotalApplied = records.TotalApplied;
              thisIns.TotalPartiallySaved = records.TotalPartiallySaved;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    getCurrentDateTime() {
      console.log("getCurrentDateTime called");

      // Get new date
      var dateObj = new Date();

      var temp_date = String(dateObj.getDate()).padStart(2, "0");
      var month = String(dateObj.getMonth() + 1).padStart(2, "0");
      var year = dateObj.getFullYear();

      // Get Current Date
      var current_date = year + "-" + month + "-" + temp_date;

      // var temp_hour = String(dateObj.getHours()).padStart(2, "0") % 12;
      // var hour = temp_hour ? temp_hour : 12;
      var hour = String(dateObj.getHours()).padStart(2, "0");
      var minute = String(dateObj.getMinutes()).padStart(2, "0");
      var second = String(dateObj.getSeconds()).padStart(2, "0");

      // Get Current Time
      var current_time = hour + ":" + minute + ":" + second;

      // Get Current AM/PM
      // var current_am_pm = dateObj.getHours() >= 12 ? "pm" : "am";

      console.log({ current_date }, { current_time });

      this.CurrentDate = current_date;
      this.CurrentTime = current_time;
      // this.CurrentAmPm = current_am_pm;

      var current_date_time = current_date + " " + current_time;

      this.CurrentDateTime = current_date_time;
    },
    getDeadlineDetails() {
      console.log("getDeadlineDetails called");

      // this.CurrentDateTime = "2023-07-01 23:00:00";
      var current_date_time = Date.parse(this.CurrentDateTime);
      console.log({ current_date_time });

      var tr = this.rows;

      // Np-Recognition Start Date
      var temp_start_date_time = tr.StartDate1;
      // temp_start_date_time = "2023-07-01 00:00:01";
      var start_date_time = Date.parse(temp_start_date_time);
      console.log({ temp_start_date_time }, { start_date_time });

      if (start_date_time <= current_date_time) {
        console.log("Opened");
        this.StartDateFlag = true;
      } else {
        console.log("Closed");
      }

      // Np-Recognition End Date

      var temp_end_date_time = tr.EndDate1;
      var end_date_time = Date.parse(temp_end_date_time);
      console.log({ temp_end_date_time }, { end_date_time });

      if (end_date_time > current_date_time) {
        console.log("Opened");
        this.EndDateFlag = true;
      } else {
        console.log("Closed");
      }

      console.log("StartDateFlag = ", this.StartDateFlag);
      console.log("EndDateFlag = ", this.EndDateFlag);
      // this.StartDateFlag = false;
      if (this.StartDateFlag && this.EndDateFlag && this.Status != "") {
        this.confirmAlert(this.Status);
      } else {
        this.deadlineAlert();
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var htmlTxt = "";
      htmlTxt +=
        "<h3> Zonecon Officers Awards Bidding ended at <b> " +
        this.ZoneconOfficersAwardEndDate +
        " </b> </h3>";
      // htmlTxt += "<br/> Start <b> " + this.ZoneconOfficersAwardEndDate + " </b>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    confirmAlert(status) {
      console.log("confirmAlert called");

      var validate1 = false;
      validate1 = status == 4 ? true : this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", false);
          }
        });
      } else {
        this.sweetAlert("error", "Kindly fill all the Questions", false);
      }
    },
    submitForm(status) {
      console.log("submitForm is called");
      this.SubmitFlag = true;

      console.log({ status });
      var count = this.AllImages.length;

      var answerData = {
        UserInterface: 1,
        ActiveStatus: status,
        ZoneCon: true,
        Award: this.AwardId,
        AwardType: this.AwardTypeId,
        CriteriaAnswers: this.row,
        AnswerAttachmentOfficers: this.row.AnswerAttachmentOfficers,
        AllProject: this.AllProject,
        AllImagesCount: count,
      };
      console.log("answerData=" + JSON.stringify(answerData));

      let upload = new FormData();
      upload.append("UserInterface", 1);
      upload.append("Form", JSON.stringify(answerData));

      this.AllImages.forEach((row, index) => {
        var id = index + 1;
        console.log({ id });
        var UploadedImage = row["UploadedImage"];
        var EventId = row["AnswerEventId"];
        console.log({ UploadedImage }, { EventId });

        upload.append("AllImages" + index, UploadedImage, EventId);
      });

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/award/officers-award-update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);

            thisIns.$router.push("/zone/officers-award/list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>
